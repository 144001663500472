@import url("../node_modules/devextreme/dist/css/dx.light.css");

@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css");

@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css");

@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css");

@import url("../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css");

@import url("../node_modules/devexpress-reporting/dist/css/dx-reportdesigner.css");
@import url("../node_modules/ace-builds/css/ace.css");  
@import url("../node_modules/ace-builds/css/theme/dreamweaver.css");  
@import url("../node_modules/ace-builds/css/theme/ambiance.css");  
@import url("../node_modules/devextreme/dist/css/dx.light.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css");
@import url("../node_modules/devexpress-dashboard/dist/css/dx-dashboard.light.css");


/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  background-color: #F5F5F5;
  display: flex;
  flex-direction: row;
  gap: 4px;
  border-radius: 4px;
}

.myInput {
  all: unset;
  width: 100%;
}

.chip {
  padding: 2px 10px;
  background: #e7e7e7;
  border-radius: 50px;
  color: #2b2b2b;
  font-weight: 500;
}

.chip>span {
  margin-left: 4px;
  cursor: pointer;
}

.inner-chips {
  width: calc(100% - 48px);
  padding: 10px;
}

.css-1jqq78o-placeholder {
  color: #4A4C59 !important;
  font-weight: 500;
}

.css-t3ipsp-control,
.css-13cymwt-control {
  box-shadow: none !important;
  border-color: #dee2e6 !important;
}

.w-120 {
  width: 120px !important;
}

.btn.small-pwd-input {
  height: 31px !important;
}